import styled from 'styled-components'
import { BREAKPOINTS } from 'styles/constants'

export const Section = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export const Box = styled.div`
  width: 340px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 280px;
    margin: 0 30px;
  }
`

export const Title = styled.div`
  margin-bottom: 28px;
  text-align: center;
`
