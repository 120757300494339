import Head from 'next/head';

const Meta = ({ meta }) => {
  const metaTitle = meta?.title || 'DS.KANBAN';

  return (
    <Head>
      <title>{metaTitle}</title>
    </Head>
  );
};

export default Meta;
