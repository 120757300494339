import styled from 'styled-components'
import { BREAKPOINTS } from 'styles/constants'

export const Text = styled.div`
  color: inherit;
  font-weight: 600;

  @media (max-width: ${BREAKPOINTS.mobile}) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (min-width: ${BREAKPOINTS.medium}) {
    font-size: 32px;
    line-height: 39px;
    max-width: 95%;
  }
`

export const TextStyle2 = (props) => (
  <Text className={props.className} {...props}>
    {props.children}
  </Text>
)
