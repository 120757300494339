import { addYears } from 'date-fns'
import { useFormik } from 'formik'
import { useRouter } from 'next/router'
import { useState } from 'react'
import * as Yup from 'yup'
import { signIn } from 'api/auth/'
import { createCookies } from 'helpers/cookie'
import Button from 'ui/components/buttons/Button/'
import Input from 'ui/components/forms/Input/'
import { notifyError } from 'ui/components/Notify'
import { TextStyle2 } from 'ui/components/typography/TextStyle2'
import { Section, Box, Title } from './styled'

const Auth = () => {
  const [pending, setPending] = useState(false)
  const router = useRouter()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Некорректный email')
        .required('Нужно заполнить'),
      password: Yup.string().required('Нужно заполнить'),
    }),
    onSubmit: (values) => {
      setPending(true)
      signIn(values).then((response) => {
        setPending(false)
        if (response?.errorMessage) {
          notifyError('Неверный логин или пароль')
        } else {
          createCookies('ac_token', response.token, {
            path: '/',
            expires: new Date(addYears(new Date(), 1)),
          })
          router.reload()
        }
      })
    },
  })

  return (
    <Section>
      <Box>
        <Title>
          <TextStyle2>Вход</TextStyle2>
        </Title>
        <form onSubmit={formik.handleSubmit}>
          <Input
            label="Электронная почта"
            name="email"
            style={{
              marginBottom: '28px',
            }}
            meta={formik.getFieldMeta('email')}
            {...formik.getFieldProps('email')}
          />
          <Input
            label="Пароль"
            name="password"
            type="password"
            style={{
              marginBottom: '28px',
            }}
            meta={formik.getFieldMeta('password')}
            {...formik.getFieldProps('password')}
          />
          <Button loading={pending} title="Войти" type="submit" />
        </form>
      </Box>
    </Section>
  )
}

export default Auth
