import { getJwtFromReq } from 'helpers/getJwtFromReq'
import Meta from 'ui/components/Meta/'
import Auth from 'ui/modules/Auth/'

const AuthPage = () => {
  return (
    <>
      <Meta
        meta={{
          title: 'Авторизация',
        }}
      />
      <Auth />
    </>
  )
}

export const getServerSideProps = async ({ req }) => {
  const accessToken = getJwtFromReq(req)

  if (accessToken) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    }
  }

  return {
    props: {},
  }
}

export default AuthPage
